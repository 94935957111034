import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";

import { ToastContainer } from "react-toastify";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Dashboards = lazy(() => import("../../router/dashboard/"));
const Orders = lazy(() => import("../../router/Order-management/"));
const NewOrder = lazy(() => import("../../router/NewOrder/"));
const AllUser = lazy(() => import("../../router/users/"));
const AllSaleCompany = lazy(() => import("../../router/sale/"));
const StickyHeader = lazy(() => import("../../router/sticky-header/"));
const SignIn = lazy(() => import("../../router/sign-in/"));
const Shipping = lazy(() => import("../../router/shipping/"));
const TaxManagement = lazy(() => import("../../router/Tax/"));
const ProductinTimeManagement = lazy(() =>
  import("../../router/ProductionTime/")
);
const InfoPagesManagement = lazy(() => import("../../router/InfoPages/"));
const ProductManagement = lazy(() => import("../../router/Product/"));
const ProductManagementDetails = lazy(() => import("../../router/Product/"));
const CouponManagement = lazy(() => import("../../router/Coupons/"));
const ShippingManagement = lazy(() =>
  import("../../router/ShippingManagement/")
);
const GeneralSettings = lazy(() => import("../../router/GeneralSettings/"));
const ShippingCalculator = lazy(() =>
  import("../../router/ShippingCalculator/")
);
const OurBrideRoute = lazy(() => import("../../router/OurBride/"));
const ReviewRoute = lazy(() => import("../../router/Review/"));
const SliderRoute = lazy(() => import("../../router/Slider/"));
const BlogRoute = lazy(() => import("../../router/Blog/"));
const PressRoomRoute = lazy(() => import("../../router/PressRoom/"));
const MainBannerRoute = lazy(() => import("../../router/MainBanner/"));
const CustomCategoryRoute = lazy(() => import("../../router/Custom-category/"));
const AllLabels = lazy(() => import("../../router/ProductLabel/"));
const PopUpBanner = lazy(() => import("../../router/PopUp-Banner/index"));
const GeneralSEO = lazy(() => import("../../router/GeneralSEO/"));
const ShippingDate = lazy(() => import("../../router/ShippingDate/"));
const SiteSettings = lazy(() => import("../../router/SiteSettings/"));
const ContactUs = lazy(() => import("../../router/ContactUs/"));
const SizeChart = lazy(() => import("../../router/SIzeChart/"));

const UpdateProduct = lazy(() => import("../../router/Product/"));

const AppMain = () => {
  return (
    <Fragment>
      {/* Dashboards */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/dashboard" component={Dashboards} />
      </Suspense>
      {/* All orders */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/order/:id" component={Orders} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/new-order" component={NewOrder} />
      </Suspense>
      {/* All users */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/all-users" component={AllUser} />
      </Suspense>
      {/* New users */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/new-user" component={AllUser} />
      </Suspense>
      {/* Edit users */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/user/:id" component={AllUser} />
      </Suspense>
      {/* Dashboard sale */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/sale-company" component={AllSaleCompany} />
      </Suspense>
      {/* Add sale */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/add-sale" component={AllSaleCompany} />
      </Suspense>
      {/* Sticky Header */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/sticky-header" component={StickyHeader} />
      </Suspense>
      {/* Sign in */}
      <Suspense fallback={<></>}>
        <PublicRoute path="/sign-in" restricted component={SignIn} />
      </Suspense>
      {/* Shipping methods */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/shipping-method" component={Shipping} />
      </Suspense>
      {/* Tax management */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/tax-management" component={TaxManagement} />
      </Suspense>
      {/* Production time management */}
      <Suspense fallback={<></>}>
        <PrivateRoute
          path="/production-time"
          component={ProductinTimeManagement}
        />
      </Suspense>
      {/* Product management */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/all-products" component={ProductManagement} />
      </Suspense>
      <Suspense fallback={<></>}>
        <PrivateRoute
          path="/new-product"
          component={ProductManagementDetails}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <PrivateRoute path="/update-products/:id" component={UpdateProduct} />
      </Suspense>
      {/*Coupon*/}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/add-coupon" component={CouponManagement} />
      </Suspense>
      <Suspense fallback={<></>}>
        <PrivateRoute path="/all-coupon" component={CouponManagement} />
      </Suspense>
      {/*End Coupon*/}
      {/*Shipping management*/}
      <Suspense fallback={<></>}>
        <PrivateRoute
          path="/all-shipping-management"
          component={ShippingManagement}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <PrivateRoute
          path="/add-shipping-management"
          component={ShippingManagement}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <PrivateRoute
          path="/shipping-manage/:id"
          component={ShippingManagement}
        />
      </Suspense>
      {/*End Coupon*/}
      {/*General settings:
                - Tag
                - Category
                - Attributes
            */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/tags" component={GeneralSettings} />
      </Suspense>
      <Suspense fallback={<></>}>
        <PrivateRoute path="/category" component={GeneralSettings} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/main-menu" component={GeneralSettings} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/attributes" component={GeneralSettings} />
      </Suspense>
      {/*shipping calculator*/}
      <Suspense fallback={<></>}>
        <PrivateRoute
          path="/shipping-calculator"
          component={ShippingCalculator}
        />
      </Suspense>
      {/*end shipping calculator*/}
      {/* our bride*/}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/our-bride" component={OurBrideRoute} />
      </Suspense>
      {/*end our bride*/}
      {/* shipping date */}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/shipping-date" component={ShippingDate} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/popUp-banner" component={PopUpBanner} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/product-labels" component={AllLabels} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/general-seo" component={GeneralSEO} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/info-pages" component={InfoPagesManagement} />
      </Suspense>

      {/*review*/}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/review" component={ReviewRoute} />
      </Suspense>
      {/*end review*/}
      {/*slider*/}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/slider" component={SliderRoute} />
      </Suspense>
      {/*end review*/}
      {/*slider*/}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/blog" component={BlogRoute} />
      </Suspense>
      <Suspense fallback={<></>}>
        <PrivateRoute path="/press-room" component={PressRoomRoute} />
      </Suspense>
      {/*end review*/}
      {/*main banner*/}
      <Suspense fallback={<></>}>
        <PrivateRoute path="/main-banner" component={MainBannerRoute} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/site-settings" component={SiteSettings} />
      </Suspense>
      <Suspense fallback={<></>}>
        <PrivateRoute path="/contact-us-messages" component={ContactUs} />
      </Suspense>

      <Suspense fallback={<></>}>
        <PrivateRoute path="/custom-category" component={CustomCategoryRoute} />
      </Suspense>
      {/*end main banner*/}

      <Suspense fallback={<></>}>
        <PrivateRoute path="/size-chart" component={SizeChart} />
      </Suspense>
      {/* exact */}

      <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
